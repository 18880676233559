import React from "react";
import ChooseUs from "./components/chooseus";
import AboutUs from "./components/about";
import BreadCrumb from "./components/breadcrumb";
function About() {
  return (
    <>
      <BreadCrumb pageName={"About Us"} pageRoute={"About"} />
      <AboutUs />
      <ChooseUs />
    </>
  );
}

export default About;
