export default function About() {
  return (
    <>
      <div class="container-fluid about bg-light py-5">
        <div class="container py-5">
          <div class="row g-5 align-items-center">
            <div class="col-lg-5 wow fadeInLeft" data-wow-delay="0.2s">
              <div class="about-img pb-5 ps-5">
                <img
                  src="img/about-1.jpg"
                  class="img-fluid rounded w-100"
                  style={{ objectFit: " cover;" }}
                  alt="Image"
                />
                <div class="about-img-inner">
                  <img
                    src="img/about-2.jpg"
                    class="img-fluid rounded-circle w-100 h-100"
                    alt="Image"
                  />
                </div>
                <div class="about-experience">15 years experience</div>
              </div>
            </div>
            <div class="col-lg-7 wow fadeInRight" data-wow-delay="0.4s">
              <div class="section-title text-start mb-5">
                <h4 class="sub-title pe-3 mb-0">About Us</h4>
                <h1 class="display-3 mb-4">
                  We are Ready to Help Improve Your Treatment.
                </h1>
                <p class="mb-4">
                  We're prepared to enhance your treatment journey. Let's
                  collaborate to optimize your health and well-being
                </p>
                <div class="mb-4">
                  <p class="text-secondary">
                    <i class="fa fa-check text-primary me-2"></i> Refresing to
                    get such a personal touch.
                  </p>
                  <p class="text-secondary">
                    <i class="fa fa-check text-primary me-2"></i> Duis aute
                    irure dolor in reprehenderit in voluptate.
                  </p>
                  <p class="text-secondary">
                    <i class="fa fa-check text-primary me-2"></i> Velit esse
                    cillum dolore eu fugiat nulla pariatur.
                  </p>
                </div>
                <a
                  href="#"
                  class="btn btn-primary rounded-pill text-white py-3 px-5"
                >
                  Discover More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
