export default function ChooseUs() {
  return (
    <>
      <div class="container-fluid feature py-5">
        <div class="container py-5">
          <div class="section-title mb-5 wow fadeInUp" data-wow-delay="0.1s">
            <div class="sub-style">
              <h4 class="sub-title px-3 mb-0">Why Choose Us</h4>
            </div>
            <h1 class="display-3 mb-4">
              Why Choose Us? Get Your Life Style Back
            </h1>
            <p class="mb-0">
              Discover the difference with us. Regain control over your
              lifestyle with our personalized care tailored to your unique
              needs. From comprehensive assessments to tailored treatment plans,
              we're committed to helping you achieve your health and wellness
              goals. Experience the expertise, compassion, and support you
              deserve. Choose us and take the first step towards reclaiming your
              lifestyle.
            </p>
          </div>
          <div class="row g-4 justify-content-center">
            <div
              class="col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div class="row-cols-1 feature-item p-4">
                <div class="col-12">
                  <div class="feature-icon mb-4">
                    <div class="p-3 d-inline-flex bg-white rounded">
                      <i class="fas fa-diagnoses fa-4x text-primary"></i>
                    </div>
                  </div>
                  <div class="feature-content d-flex flex-column">
                    <h5 class="mb-4">Licensed Therapist</h5>
                    <p class="mb-0">
                      Licensed Therapist: Qualified, compassionate care
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div class="row-cols-1 feature-item p-4">
                <div class="col-12">
                  <div class="feature-icon mb-4">
                    <div class="p-3 d-inline-flex bg-white rounded">
                      <i class="fas fa-briefcase-medical fa-4x text-primary"></i>
                    </div>
                  </div>
                  <div class="feature-content d-flex flex-column">
                    <h5 class="mb-4">Personalized Treatment</h5>
                    <p class="mb-0">
                      Personalized Treatment: Tailored care for you.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div class="row-cols-1 feature-item p-4">
                <div class="col-12">
                  <div class="feature-icon mb-4">
                    <div class="p-3 d-inline-flex bg-white rounded">
                      <i class="fas fa-hospital-user fa-4x text-primary"></i>
                    </div>
                  </div>
                  <div class="feature-content d-flex flex-column">
                    <h5 class="mb-4">Therapy Goals</h5>
                    <p class="mb-0">Therapy Goals: Achieve your best self.</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div class="row-cols-1 feature-item p-4">
                <div class="col-12">
                  <div class="feature-icon mb-4">
                    <div class="p-3 d-inline-flex bg-white rounded">
                      <i class="fas fa-users fa-4x text-primary"></i>
                    </div>
                  </div>
                  <div class="feature-content d-flex flex-column">
                    <h5 class="mb-4">Practitioners Network</h5>
                    <p class="mb-0">
                      Practitioners Network: Connecting professionals.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div class="row-cols-1 feature-item p-4">
                <div class="col-12">
                  <div class="feature-icon mb-4">
                    <div class="p-3 d-inline-flex bg-white rounded">
                      <i class="fas fa-spa fa-4x text-primary"></i>
                    </div>
                  </div>
                  <div class="feature-content d-flex flex-column">
                    <h5 class="mb-4">Comfortable Center</h5>
                    <p class="mb-0">Comfortable Center: Your tranquil space.</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div class="row-cols-1 feature-item p-4">
                <div class="col-12">
                  <div class="feature-icon mb-4">
                    <div class="p-3 d-inline-flex bg-white rounded">
                      <i class="fas fa-heart fa-4x text-primary"></i>
                    </div>
                  </div>
                  <div class="feature-content d-flex flex-column">
                    <h5 class="mb-4">Experienced Stuff</h5>
                    <p class="mb-0">
                      Experienced Staff: Trusted professionals.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div class="row-cols-1 feature-item p-4">
                <div class="col-12">
                  <div class="feature-icon mb-4">
                    <div class="p-3 d-inline-flex bg-white rounded">
                      <i class="fab fa-pied-piper fa-4x text-primary"></i>
                    </div>
                  </div>
                  <div class="feature-content d-flex flex-column">
                    <h5 class="mb-4">Therapy Goals</h5>
                    <p class="mb-0">Therapy Goals: Achieve your best self.</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div class="row-cols-1 feature-item p-4">
                <div class="col-12">
                  <div class="feature-icon mb-4">
                    <div class="p-3 d-inline-flex bg-white rounded">
                      <i class="fas fa-user-md fa-4x text-primary"></i>
                    </div>
                  </div>
                  <div class="feature-content d-flex flex-column">
                    <h5 class="mb-4">Licensed Therapist</h5>
                    <p class="mb-0">
                      Licensed Therapist: Qualified, compassionate care
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 text-center wow fadeInUp" data-wow-delay="0.2s">
              <a
                href="#"
                class="btn btn-primary rounded-pill text-white py-3 px-5"
              >
                More Details
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
