import React from "react";
import HeroSection from "./components/HeroSection";
import Services from "./components/Services";
import About from "./components/about";
import ChooseUs from "./components/chooseus";
import BookAppointment from "./components/bookAppointment";
import Testimonial from './components/testimonials';
function Home() {
  return (
    <div className="App">
      <HeroSection />
      <Services />
      <About />
      <ChooseUs />
      <BookAppointment />
      <Testimonial />
    </div>
  );
}

export default Home;
