import React from "react";
import Services from "./components/Services";
import BreadCrumb from "./components/breadcrumb";
import ChooseUs from "./components/chooseus";
import Testimonial from "./components/testimonials";

function Service() {
  return (
    <>
      <BreadCrumb pageName={"Our Services"} pageRoute={"Service"} />
      <Services />
      <ChooseUs />
      <Testimonial />
    </>
  );
}

export default Service;
