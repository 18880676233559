export default function Services() {
  return (
    <>
      <div class="container-fluid service py-5">
        <div class="container py-5">
          <div class="section-title mb-5 wow fadeInUp" data-wow-delay="0.2s">
            <div class="sub-style">
              <h4 class="sub-title px-3 mb-0">What We Do</h4>
            </div>
            <h1 class="display-3 mb-4">
              Our Service Given Physio Therapy By Expert.
            </h1>
            <p class="mb-0">
              At our physiotherapy clinic, we are dedicated to providing
              exceptional care tailored to your unique needs. Our team of
              experienced physiotherapists is committed to helping you regain
              mobility, reduce pain, and improve your overall quality of life.
            </p>
          </div>
          <div class="row g-4 justify-content-center">
            <div
              class="col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div class="service-item rounded">
                <div class="service-img rounded-top">
                  <img
                    src="img/service-1.jpg"
                    class="img-fluid rounded-top w-100"
                    alt=""
                  />
                </div>
                <div class="service-content rounded-bottom bg-light p-4">
                  <div class="service-content-inner">
                    <h5 class="mb-4">Message Therapy</h5>
                    <p class="mb-4">
                      Discover relaxation and relief with our tailored massage
                      therapy sessions. Let our skilled therapists soothe your
                      tensions and rejuvenate your body
                    </p>
                    <a
                      href="#"
                      class="btn btn-primary rounded-pill text-white py-2 px-4 mb-2"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div class="service-item rounded">
                <div class="service-img rounded-top">
                  <img
                    src="img/service-2.jpg"
                    class="img-fluid rounded-top w-100"
                    alt=""
                  />
                </div>
                <div class="service-content rounded-bottom bg-light p-4">
                  <div class="service-content-inner">
                    <h5 class="mb-4">Physiotherapy</h5>
                    <p class="mb-4">
                      Unlock a pain-free life with our expert physiotherapy
                      services. Tailored treatments designed for your specific
                      needs, helping you live your best life.
                    </p>
                    <a
                      href="#"
                      class="btn btn-primary rounded-pill text-white py-2 px-4 mb-2"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div class="service-item rounded">
                <div class="service-img rounded-top">
                  <img
                    src="img/service-3.jpg"
                    class="img-fluid rounded-top w-100"
                    alt=""
                  />
                </div>
                <div class="service-content rounded-bottom bg-light p-4">
                  <div class="service-content-inner">
                    <h5 class="mb-4">Heat & Cold Therapy</h5>
                    <p class="mb-4">
                      Discover relief with our specialized heat and cold therapy
                      treatments. Ease pain and promote healing for a happier,
                      healthier you.
                    </p>
                    <a
                      href="#"
                      class="btn btn-primary rounded-pill text-white py-2 px-4 mb-2"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div class="service-item rounded">
                <div class="service-img rounded-top">
                  <img
                    src="img/service-4.jpg"
                    class="img-fluid rounded-top w-100"
                    alt=""
                  />
                </div>
                <div class="service-content rounded-bottom bg-light p-4">
                  <div class="service-content-inner">
                    <h5 class="mb-4">Chiropatic Therapy</h5>
                    <p class="mb-4">
                      Chiropractic therapy: Aligning your body for optimal
                      wellness. Experience natural healing and improved mobility
                      with our expert care.
                    </p>
                    <a
                      href="#"
                      class="btn btn-primary rounded-pill text-white py-2 px-4 mb-2"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div class="service-item rounded">
                <div class="service-img rounded-top">
                  <img
                    src="img/service-5.jpg"
                    class="img-fluid rounded-top w-100"
                    alt=""
                  />
                </div>
                <div class="service-content rounded-bottom bg-light p-4">
                  <div class="service-content-inner">
                    <h5 class="mb-4">Work Injuries</h5>
                    <p class="mb-4">
                      Work Injuries: Regain strength and mobility with our
                      specialized rehabilitation programs. Get back on your feet
                      confidently and safely.
                    </p>
                    <a
                      href="#"
                      class="btn btn-primary rounded-pill text-white py-2 px-4 mb-2"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div class="service-item rounded">
                <div class="service-img rounded-top">
                  <img
                    src="img/service-6.jpg"
                    class="img-fluid rounded-top w-100"
                    alt=""
                  />
                </div>
                <div class="service-content rounded-bottom bg-light p-4">
                  <div class="service-content-inner">
                    <h5 class="mb-4">Spot Injuries</h5>
                    <p class="mb-4">
                      Spot Injuries: Targeted care for localized discomfort. Our
                      precise treatments aim to alleviate pain and restore
                      function quickly.
                    </p>
                    <a
                      href="#"
                      class="btn btn-primary rounded-pill text-white py-2 px-4 mb-2"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div class="service-item rounded">
                <div class="service-img rounded-top">
                  <img
                    src="img/service-7.jpg"
                    class="img-fluid rounded-top w-100"
                    alt=""
                  />
                </div>
                <div class="service-content rounded-bottom bg-light p-4">
                  <div class="service-content-inner">
                    <h5 class="mb-4">Regular Therapy</h5>
                    <p class="mb-4">
                      Regular Therapy: Consistent support for your mental
                      well-being. Our sessions provide a safe space for growth,
                      healing, and self-discovery.
                    </p>
                    <a
                      href="#"
                      class="btn btn-primary rounded-pill text-white py-2 px-4 mb-2"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-6 col-lg-4 col-xl-3 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div class="service-item rounded">
                <div class="service-img rounded-top">
                  <img
                    src="img/service-8.jpg"
                    class="img-fluid rounded-top w-100"
                    alt=""
                  />
                </div>
                <div class="service-content rounded-bottom bg-light p-4">
                  <div class="service-content-inner">
                    <h5 class="mb-4">Back Pain</h5>
                    <p class="mb-4">
                      Back Pain: Find relief and restore mobility with our
                      personalized treatments. Get back to enjoying life without
                      discomfort.
                    </p>
                    <a
                      href="#"
                      class="btn btn-primary rounded-pill text-white py-2 px-4 mb-2"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 text-center wow fadeInUp" data-wow-delay="0.2s">
              <a
                class="btn btn-primary rounded-pill text-white py-3 px-5"
                href="#"
              >
                Services More
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
