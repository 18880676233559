import { NavLink } from "react-router-dom";

export default function BreadCrumb({ pageName, pageRoute }) {
  return (
    <>
      <div class="container-fluid bg-breadcrumb">
        <div class="container text-center py-5" style={{ maxWidth: "900px;" }}>
          <h3
            class="text-white display-3 mb-4 wow fadeInDown"
            data-wow-delay="0.1s"
          >
            {pageName}
          </h3>
          <ol
            class="breadcrumb justify-content-center mb-0 wow fadeInDown"
            data-wow-delay="0.3s"
          >
            <li class="breadcrumb-item">
              <NavLink to="/">Home</NavLink>
            </li>
            <li class="breadcrumb-item active text-primary">{pageRoute}</li>
          </ol>
        </div>
      </div>
    </>
  );
}
