import { NavLink } from "react-router-dom";

export default function Footer() {
  return (
    <div class="container-fluid footer py-5 wow fadeIn" data-wow-delay="0.2s">
      <div class="container py-5">
        <div class="row g-5">
          <div class="col-md-6 col-lg-6 col-xl-3">
            <div class="footer-item d-flex flex-column">
              <h4 class="text-white mb-4">
                <img src="/logo.jpeg" width={"100%"} />
              </h4>
              {/* <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Delectus dolorem impedit eos autem dolores laudantium quia, qui
                similique
              </p> */}
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xl-3">
            <div class="footer-item d-flex flex-column">
              <h4 class="mb-4 text-white">Quick Links</h4>
              <NavLink to="/about">
                <i class="fas fa-angle-right me-2"></i> About Us
              </NavLink>
              <NavLink to="/contact">
                <i class="fas fa-angle-right me-2"></i> Contact Us
              </NavLink>
              <a href="">
                <i class="fas fa-angle-right me-2"></i> Privacy Policy
              </a>
              <a href="">
                <i class="fas fa-angle-right me-2"></i> Terms & Conditions
              </a>
              <a href="">
                <i class="fas fa-angle-right me-2"></i> Our Blog & News
              </a>
              <a href="">
                <i class="fas fa-angle-right me-2"></i> Our Team
              </a>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xl-3">
            <div class="footer-item d-flex flex-column">
              <h4 class="mb-4 text-white">Terapia Services</h4>
              <NavLink to="/service">
                <i class="fas fa-angle-right me-2"></i> All Services
              </NavLink>
              <a href="">
                <i class="fas fa-angle-right me-2"></i> Physiotherapy
              </a>
              <a href="">
                <i class="fas fa-angle-right me-2"></i> Diagnostics
              </a>
              <a href="">
                <i class="fas fa-angle-right me-2"></i> Manual Therapy
              </a>
              <a href="">
                <i class="fas fa-angle-right me-2"></i> Massage Therapy
              </a>
              <a href="">
                <i class="fas fa-angle-right me-2"></i> Rehabilitation
              </a>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xl-3">
            <div class="footer-item d-flex flex-column">
              <h4 class="mb-4 text-white">Contact Info</h4>
              <a href="">
                <i class="fa fa-map-marker-alt me-2"></i> Health Plus Physiotherapy Center Nathdwara
              </a>
              {/* <a href="">
                <i class="fas fa-envelope me-2"></i> info@example.com
              </a>
              <a href="">
                <i class="fas fa-envelope me-2"></i> info@example.com
              </a> */}
              {/* <a href="">
                <i class="fas fa-phone me-2"></i> +012 345 67890
              </a>
              <a href="" class="mb-3">
                <i class="fas fa-print me-2"></i> +012 345 67890
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
