import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

export default function NavBar() {
  const router = useLocation();
  return (
    <div>
      {/* <div class="container-fluid bg-dark px-5 d-none d-lg-block">
        <div class="row gx-0 align-items-center" style={{ height: "45px;" }}>
          <div class="col-lg-8 text-center text-lg-start mb-lg-0">
            <div class="d-flex flex-wrap">
              <a href="#" class="text-light me-4">
                <i class="fas fa-map-marker-alt text-primary me-2"></i>Find A
                Location
              </a>
              <a href="#" class="text-light me-4">
                <i class="fas fa-phone-alt text-primary me-2"></i>+01234567890
              </a>
              <a href="#" class="text-light me-0">
                <i class="fas fa-envelope text-primary me-2"></i>
                Example@gmail.com
              </a>
            </div>
          </div>
          <div class="col-lg-4 text-center text-lg-end">
            <div class="d-flex align-items-center justify-content-end">
              <a
                href="#"
                class="btn btn-light btn-square border rounded-circle nav-fill me-3"
              >
                <i class="fab fa-facebook-f"></i>
              </a>
              <a
                href="#"
                class="btn btn-light btn-square border rounded-circle nav-fill me-3"
              >
                <i class="fab fa-twitter"></i>
              </a>
              <a
                href="#"
                class="btn btn-light btn-square border rounded-circle nav-fill me-3"
              >
                <i class="fab fa-instagram"></i>
              </a>
              <a
                href="#"
                class="btn btn-light btn-square border rounded-circle nav-fill me-0"
              >
                <i class="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
        </div>
      </div> */}
      <nav class="navbar navbar-expand-lg navbar-light bg-white px-4 px-lg-5 py-3 py-lg-0">
        <NavLink to="/" class="navbar-brand p-0">
          <h1 class="text-primary m-0">
            <img src="/logo.jpeg" width={"150px"} />
          </h1>
          {/* <img src="img/logo.png" alt="Logo"> */}
        </NavLink>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span class="fa fa-bars"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <div class="navbar-nav ms-auto py-0">
            <NavLink
              to="/"
              className={
                router.pathname === "/"
                  ? "nav-item nav-link active"
                  : "nav-item nav-link"
              }
            >
              Home
            </NavLink>
            <NavLink
              to="/about"
              className={
                router.pathname === "/about"
                  ? "nav-item nav-link active"
                  : "nav-item nav-link"
              }
            >
              About
            </NavLink>
            <NavLink
              to="/service"
              className={
                router.pathname === "/service"
                  ? "nav-item nav-link active"
                  : "nav-item nav-link"
              }
            >
              Services
            </NavLink>
            <NavLink
              to="/contact"
              className={
                router.pathname === "/contact"
                  ? "nav-item nav-link active"
                  : "nav-item nav-link"
              }
            >
              Contact Us
            </NavLink>
          </div>
        </div>
      </nav>
    </div>
  );
}
