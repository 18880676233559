
import OwlCarousel from 'react-owl-carousel';
export default function HeroSection() {
  const options = {
    animateOut: "slideOutDown",
    items: 1,
    autoplay: true,
    smartSpeed: 1000,
    dots: false,
    loop: true,
    nav: true,
    navText: [
      '<i class="fa fa-arrow-left"></i>',
      '<i class="fa fa-arrow-right"></i>',
    ],
  };
  return (
    <>
      <OwlCarousel className="header-carousel" {...options}>
        <div className="item header-carousel-item">
          <img
            src="img/dr-kl-parmar-01.JPG"
            class="img-fluid w-100"
            alt="Image"
          />
          <div class="carousel-caption">
            <div class="carousel-caption-content p-3">
              <h5 class="text-white text-uppercase fw-bold mb-4">
                Physiotherapy Center
              </h5>
              <h1 class="display-1 text-capitalize text-white mb-4">
                Best Solution For Painful Life
              </h1>
              <p class="mb-5 fs-5">
                Unlock a pain-free life with our expert physiotherapy services.
                Tailored treatments designed for your specific needs, helping
                you live your best life.
              </p>
              <a
                class="btn btn-primary rounded-pill text-white py-3 px-5"
                href="#"
              >
                Book Appointment
              </a>
            </div>
          </div>
        </div>
        <div className="item header-carousel-item">
          <img
            src="img/dr-kl-parmar-02.JPG"
            class="img-fluid w-100"
            alt="Image"
          />
          <div class="carousel-caption">
            <div class="carousel-caption-content p-3">
              <h5 class="text-white text-uppercase fw-bold mb-4">
                Physiotherapy Center
              </h5>
              <h1 class="display-1 text-capitalize text-white mb-4">
                Best Solution For Painful Life
              </h1>
              <p class="mb-5 fs-5 animated slideInDown">
                Unlock a pain-free life with our expert physiotherapy services.
                Tailored treatments designed for your specific needs, helping
                you live your best life.
              </p>
              <a
                class="btn btn-primary rounded-pill text-white py-3 px-5"
                href="#"
              >
                Book Appointment
              </a>
            </div>
          </div>
        </div>
        {/* <div className="item header-carousel-item"></div> */}
      </OwlCarousel>
    </>
  );
}
