import OwlCarousel from 'react-owl-carousel';
export default function Testimonial() {
  const options = {
    autoplay: true,
    items: 1,
    smartSpeed: 1500,
    dots: true,
    loop: true,
    margin: 25,
    nav : true,
    navText: [
      '<i class="fa fa-arrow-left"></i>',
      '<i class="fa fa-arrow-right"></i>',
    ],
  };
  return (
    <>
      <div
        class="container-fluid testimonial py-5 wow zoomInDown"
        data-wow-delay="0.1s"
      >
        <div class="container py-5">
          <div class="section-title mb-5">
            <div class="sub-style">
              <h4 class="sub-title text-white px-3 mb-0">Testimonial</h4>
            </div>
            <h1 class="display-3 mb-4">What Clients are Say</h1>
          </div>
          <OwlCarousel className="testimonial-carousel" {...options}>
            <div class="item testimonial-item">
              <div class="testimonial-inner p-5">
                <div class="testimonial-inner-img mb-4">
                  <img
                    src="img/testimonial-img.jpg"
                    class="img-fluid rounded-circle"
                    alt=""
                  />
                </div>
                <p class="text-white fs-7">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Asperiores nemo facilis tempora esse explicabo sed!
                  Dignissimos quia ullam pariatur blanditiis sed voluptatum.
                  Totam aut quidem laudantium tempora. Minima, saepe earum!
                </p>
                <div class="text-center">
                  <h5 class="mb-2">John Abraham</h5>
                  <p class="mb-2 text-white-50">New York, USA</p>
                  <div class="d-flex justify-content-center">
                    <i class="fas fa-star text-secondary"></i>
                    <i class="fas fa-star text-secondary"></i>
                    <i class="fas fa-star text-secondary"></i>
                    <i class="fas fa-star text-secondary"></i>
                    <i class="fas fa-star text-secondary"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="item testimonial-item">
              <div class="testimonial-inner p-5">
                <div class="testimonial-inner-img mb-4">
                  <img
                    src="img/testimonial-img.jpg"
                    class="img-fluid rounded-circle"
                    alt=""
                  />
                </div>
                <p class="text-white fs-7">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Asperiores nemo facilis tempora esse explicabo sed!
                  Dignissimos quia ullam pariatur blanditiis sed voluptatum.
                  Totam aut quidem laudantium tempora. Minima, saepe earum!
                </p>
                <div class="text-center">
                  <h5 class="mb-2">John Abraham</h5>
                  <p class="mb-2 text-white-50">New York, USA</p>
                  <div class="d-flex justify-content-center">
                    <i class="fas fa-star text-secondary"></i>
                    <i class="fas fa-star text-secondary"></i>
                    <i class="fas fa-star text-secondary"></i>
                    <i class="fas fa-star text-secondary"></i>
                    <i class="fas fa-star text-secondary"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="item testimonial-item">
              <div class="testimonial-inner p-5">
                <div class="testimonial-inner-img mb-4">
                  <img
                    src="img/testimonial-img.jpg"
                    class="img-fluid rounded-circle"
                    alt=""
                  />
                </div>
                <p class="text-white fs-7">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Asperiores nemo facilis tempora esse explicabo sed!
                  Dignissimos quia ullam pariatur blanditiis sed voluptatum.
                  Totam aut quidem laudantium tempora. Minima, saepe earum!
                </p>
                <div class="text-center">
                  <h5 class="mb-2">John Abraham</h5>
                  <p class="mb-2 text-white-50">New York, USA</p>
                  <div class="d-flex justify-content-center">
                    <i class="fas fa-star text-secondary"></i>
                    <i class="fas fa-star text-secondary"></i>
                    <i class="fas fa-star text-secondary"></i>
                    <i class="fas fa-star text-secondary"></i>
                    <i class="fas fa-star text-secondary"></i>
                  </div>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </div>
    </>
  );
}
