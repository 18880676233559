export default function BookAppointment() {
  return (
    <>
      <div class="container-fluid appointment py-5">
        <div class="container py-5">
          <div class="row g-5 align-items-center">
            <div class="col-lg-6 wow fadeInLeft" data-wow-delay="0.2">
              <div class="section-title text-start">
                <h4 class="sub-title pe-3 mb-0">Solutions To Your Pain</h4>
                <h1 class="display-4 mb-4">
                  Best Quality Services With Minimal Pain Rate
                </h1>
                <p class="mb-4">
                  Discover unparalleled quality in our services, meticulously
                  designed to minimize discomfort while maximizing
                  effectiveness. Trust in our commitment to excellence for a
                  smoother, pain-free experience
                </p>
                <div class="row g-4">
                  <div class="col-sm-6">
                    <div class="d-flex flex-column h-100">
                      <div class="mb-4">
                        <h5 class="mb-3">
                          <i class="fa fa-check text-primary me-2"></i> Body
                          Relaxation
                        </h5>
                        <p class="mb-0">
                          Indulge in complete body relaxation with our expert
                          techniques, designed to alleviate tension and promote
                          overall well-being
                        </p>
                      </div>
                      <div class="mb-4">
                        <h5 class="mb-3">
                          <i class="fa fa-check text-primary me-2"></i> Body
                          Relaxation
                        </h5>
                        <p class="mb-0">
                          Indulge in complete body relaxation with our expert
                          techniques, designed to alleviate tension and promote
                          overall well-being
                        </p>
                      </div>
                      <div class="text-start mb-4">
                        <a
                          href="#"
                          class="btn btn-primary rounded-pill text-white py-3 px-5"
                        >
                          More Details
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="video h-100">
                      <img
                        src="img/video-img.jpg"
                        class="img-fluid rounded w-100 h-100"
                        style={{ objectFit: " cover;" }}
                        alt=""
                      />
                      <button
                        type="button"
                        class="btn btn-play"
                        data-bs-toggle="modal"
                        data-src="https://www.youtube.com/embed/DWRcNpR6Kdc"
                        data-bs-target="#videoModal"
                      >
                        <span></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 wow fadeInRight" data-wow-delay="0.4s">
              <div class="appointment-form rounded p-5">
                <p class="fs-4 text-uppercase text-primary">Get In Touch</p>
                <h1 class="display-5 mb-4">Get Appointment</h1>
                <form>
                  <div class="row gy-3 gx-4">
                    <div class="col-xl-6">
                      <input
                        type="text"
                        class="form-control py-3 border-primary bg-transparent text-white"
                        placeholder="First Name"
                      />
                    </div>
                    <div class="col-xl-6">
                      <input
                        type="email"
                        class="form-control py-3 border-primary bg-transparent text-white"
                        placeholder="Email"
                      />
                    </div>
                    <div class="col-xl-6">
                      <input
                        type="phone"
                        class="form-control py-3 border-primary bg-transparent"
                        placeholder="Phone"
                      />
                    </div>
                    <div class="col-xl-6">
                      <select
                        class="form-select py-3 border-primary bg-transparent"
                        aria-label="Default select example"
                      >
                        <option selected>Your Gender</option>
                        <option value="1">Male</option>
                        <option value="2">FeMale</option>
                        <option value="3">Others</option>
                      </select>
                    </div>
                    <div class="col-xl-6">
                      <input
                        type="date"
                        class="form-control py-3 border-primary bg-transparent"
                      />
                    </div>
                    <div class="col-xl-6">
                      <select
                        class="form-select py-3 border-primary bg-transparent"
                        aria-label="Default select example"
                      >
                        <option selected>Department</option>
                        <option value="1">Physiotherapy</option>
                        <option value="2">Physical Helth</option>
                        <option value="2">Treatments</option>
                      </select>
                    </div>
                    <div class="col-12">
                      <textarea
                        class="form-control border-primary bg-transparent text-white"
                        name="text"
                        id="area-text"
                        cols="30"
                        rows="5"
                        placeholder="Write Comments"
                      ></textarea>
                    </div>
                    <div class="col-12">
                      <button
                        type="button"
                        class="btn btn-primary text-white w-100 py-3 px-5"
                      >
                        SUBMIT NOW
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
